import { omit, compose, orderBy, flatMap, filter } from 'lodash/fp'
import { v4 as uuid } from 'uuid'

import CustomerApiClient, { PORTAL_TYPES } from 'api/CustomerApiClient'
import { CUSTOMER_QUERY_IDS } from 'api/Queries'
import {
  Contact,
  PendingChange,
  RepeatableAddress,
  RepeatableEmail,
  RepeatablePhone
} from 'context/ContactContext'
import useEcpTranslation from 'hooks/common/useEcpTranslation'
import {
  MOCK_CONTRACTS,
  MOCK_METERS,
  MOCK_OPPORTUNITIES,
  MOCK_ORDERS
} from 'mock-db/MockedData'

import {
  RepeatablePaymentOption,
  RepeatablePaymentOptionType
} from './PaymentUtils'
import RouteUtils from './RouteUtils'
import { Flow, Workflow, WorkflowStep } from './WorkflowUtils'

export enum EntityType {
  CONTACT = 'contact',
  ORDER = 'order',
  OPPORTUNITY = 'opportunity',
  CONTRACT = 'contract',
  METER = 'meter',
  METER_COUNTER = 'meter_counter',
  ACCOUNT = 'account',
  DOCUMENT = 'file',
  BILLING_EVENT = 'billing_event'
}

export const generateKey = () => {
  return Math.random().toString(36).substring(2, 9)
}

const mapContactWithRepeatableIds = (contact: Contact): Contact => {
  const _pending_changes = { ...contact._pending_changes }

  return {
    ...contact,
    email: contact?.email?.map((email: RepeatableEmail) => ({
      ...email,
      id: generateKey()
    })),
    phone:
      contact?.phone?.map((phone: RepeatablePhone) => ({
        ...phone,
        id: generateKey()
      })) || null,
    address:
      contact?.address?.map((address: RepeatableAddress) => ({
        ...address,
        id: generateKey()
      })) || null,
    payment:
      contact?.payment?.map((payment: RepeatablePaymentOption) => ({
        ...payment,
        id: generateKey()
      })) || null,
    _pending_changes
  }
}

export const createPendingChange = (newValue: unknown): PendingChange => {
  return {
    new_value: newValue,
    requested_at: new Date(Date.now()).toISOString(),
    requested_by: 'ecp'
  }
}

const unmapContactOfRepeatableIds = (contact: Contact): Contact => {
  return {
    ...contact,
    email: contact?.email?.map(
      (email: RepeatableEmail) => omit('id')(email) as RepeatableEmail
    ),
    phone: contact?.phone?.map(
      (phone: RepeatablePhone) => omit('id')(phone) as RepeatablePhone
    ),
    address: contact?.address?.map(
      (address: RepeatableAddress) => omit('id')(address) as RepeatableAddress
    ),
    payment: contact?.payment?.map(
      (payment: RepeatablePaymentOption) =>
        omit('id')(payment) as RepeatablePaymentOption
    )
  }
}

const emptyRepeatable = {
  email: () => ({
    id: generateKey(),
    _tags: [],
    email: ''
  }),
  phone: () => ({
    id: generateKey(),
    _tags: [],
    phone: ''
  }),
  address: () => ({
    id: generateKey(),
    _tags: [],
    country: '',
    city: '',
    postal_code: '',
    street: '',
    street_number: '',
    additional_info: ''
  }),
  paymentInvoice: () => ({
    id: generateKey(),
    _id: uuid(),
    _tags: [],
    type: RepeatablePaymentOptionType.invoice
  }),
  paymentCash: () => ({
    id: generateKey(),
    _id: uuid(),
    _tags: [],
    type: RepeatablePaymentOptionType.CASH
  }),
  paymentSEPA: (): RepeatablePaymentOption => ({
    id: generateKey(),
    _id: uuid(),
    _tags: [],
    type: RepeatablePaymentOptionType.SEPA,
    data: {
      bank_name: '',
      iban: '',
      bic_number: '',
      fullname: ''
    }
  })
}

interface shouldWorkflowStepBeVisible {
  step: WorkflowStep
  origin: string
}

const shouldWorkflowStepBeVisible = (
  params: shouldWorkflowStepBeVisible
): boolean => {
  if (params.origin === PORTAL_TYPES.endCustomerPortal) {
    // Default 'enabled' property to TRUE if it doesn't exist on the object at all
    // This is a backwards compatibility mechanism for Workflow Steps that contain
    // ECP label but no 'enabled' property
    if (params.step?.ecp && !('enabled' in params.step.ecp)) {
      return !!params.step?.ecp?.label
    }

    return params.step.ecp?.enabled && !!params.step?.ecp?.label
  }

  if (params.origin === PORTAL_TYPES.installerPortal) {
    // Default 'enabled' property to TRUE if it doesn't exist on the object at all
    // This is a backwards compatibility mechanism for Workflow Steps that contain
    // INSTALLER label but no 'enabled' property
    if (params.step?.installer && !('enabled' in params.step.installer)) {
      return !!params.step?.installer?.label
    }

    return params.step.installer?.enabled && !!params.step?.installer?.label
  }

  return params.step.ecp?.enabled && !!params.step?.ecp?.label
}

const useGetWorkflow = (
  workflows: Workflow[],
  origin: string
): { workflows: { steps: WorkflowStep[]; workflow: Workflow }[] } => {
  // Backwards compatibility with new workflow ecp information
  if (!workflows) {
    return null
  }

  const ecpWorkflows = compose(
    orderBy(
      (workflow: Workflow) => new Date(workflow.lastUpdateTime),
      ['desc']
    ),
    filter((workflow: Workflow) => {
      const hasEcpStep = workflow.flow.find((flow: any) => {
        if (flow?.type === 'SECTION') {
          return flow?.steps?.find((sectionStep: WorkflowStep) => {
            return shouldWorkflowStepBeVisible({
              step: sectionStep,
              origin
            })
          })
        }

        if (flow?.type === 'STEP') {
          return shouldWorkflowStepBeVisible({
            step: flow,
            origin
          })
        }

        throw new Error('Workflow has a step which is of unknown type')
      })

      return !!hasEcpStep
    })
  )(workflows) as Workflow[]

  const allWorkflows = ecpWorkflows.map((workflow) => {
    return {
      workflow: workflow,
      steps: getWorkflowSteps(workflow?.flow, origin)
    }
  })

  return {
    workflows: allWorkflows
  }
}

const getWorkflowSteps = (flow: Flow[], origin: string): WorkflowStep[] => {
  if (!flow) {
    return []
  }

  const stepsInRightOrder = flatMap((item: any) => {
    if (item.type === 'STEP') {
      return item
    }

    return item.steps
  })(flow)

  return stepsInRightOrder.filter((step: WorkflowStep) => {
    if (origin === PORTAL_TYPES.endCustomerPortal) {
      if (step.ecp?.enabled || (step?.ecp && !('enabled' in step.ecp))) {
        return !!step?.ecp?.label
      }
    }

    if (origin === PORTAL_TYPES.installerPortal) {
      if (
        step.installer?.enabled ||
        (step?.installer && !('enabled' in step.installer))
      ) {
        return !!step?.installer?.label
      }
    }

    return false
  })
}

const getEntityQueryIdBySchema = (schema: string): string => {
  const queryBySchemaMap = {
    contact: 'GET_CONTACT',
    opportunity: 'GET_OPPORTUNITY_DETAILS',
    order: 'GET_ORDER_DETAILS',
    contract: 'GET_CONTRACT_DETAILS',
    meter: 'GET_METER_DETAILS'
  }

  return queryBySchemaMap[schema]
}

type QueryBySchema = {
  queryId: string
  queryFn: () => Promise<any>
}

export const getGetAllQueryCalBySchema = (schema: string): QueryBySchema => {
  const queryBySchemaMap = {
    opportunity: {
      queryId: CUSTOMER_QUERY_IDS.GET_ALL_OPPORTUNITIES,
      queryFn: CustomerApiClient.getAllOpportunities
    },
    order: {
      queryId: CUSTOMER_QUERY_IDS.GET_ALL_ORDERS,
      queryFn: CustomerApiClient.getAllOrders
    },
    contract: {
      queryId: CUSTOMER_QUERY_IDS.GET_CONTRACTS,
      queryFn: CustomerApiClient.getAllContracts
    }
  }

  return queryBySchemaMap[schema]
}

export const getRouteBySchema = (schema: string) => {
  switch (schema) {
    case 'contact':
      return RouteUtils.myAccountRoute()
    case 'contract':
      return RouteUtils.contractsRoute()
    case 'opportunity':
      return RouteUtils.opportunitiesRoute()
    case 'order':
      return RouteUtils.ordersRoute()
    case 'meter':
      return RouteUtils.metersRoute()
    default:
      return ''
  }
}

export const getMockDataBySchema = (schema: string) => {
  switch (schema) {
    case 'contract':
      return MOCK_CONTRACTS
    case 'opportunity':
      return MOCK_OPPORTUNITIES
    case 'order':
      return MOCK_ORDERS
    case 'meter':
      return MOCK_METERS
    default:
      return []
  }
}

export const showAttributesBySchema = (schema: string) => {
  switch (schema) {
    case 'contract':
      return ['contract_name', 'status', '_created_at']
    case 'opportunity':
      return ['opportunity_name', 'status', '_created_at']
    case 'order':
      return ['order_name', 'status', '_created_at']
    case 'meter':
      return ['meter_name', 'status', '_created_at']
    default:
      return []
  }
}

export const useIdentifierToLabel = () => {
  const { t } = useEcpTranslation()

  return (identifier: string) => {
    const identifierLabel = identifier
      .split('_')
      .join(' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())

    return t(`attributes.${identifierLabel}`, { defaultValue: identifierLabel })
  }
}

export default {
  generateKey,
  mapContactWithRepeatableIds,
  unmapContactOfRepeatableIds,
  emptyRepeatable,
  useGetWorkflow,
  getEntityQueryIdBySchema,
  getGetAllQueryCalBySchema
}
